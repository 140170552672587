import { useEffect } from 'react';
import { Auth } from 'aws-amplify';

const CognitoHostedUIRedirect = () => {
  useEffect(() => {
    const { searchParams } = new URL(window.location.href);
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    if (!code || !state) {
      Auth.federatedSignIn();
    }
  }, []);

  return (
    <p>Checking authentication...</p>
  );
};

export default CognitoHostedUIRedirect;
