import DataProvider from './DataProvider';

function buildDataProvider(operations, options = {}) {
  const dataProvider = new DataProvider(operations, options);

  return {
    getList: async (resource, params) => dataProvider.getList(resource, params),
    getOne: async (resource, params) => dataProvider.getOne(resource, params),
    getMany: async (resource, params) => dataProvider.getMany(resource, params),
    getManyReference: async (resource, params) => dataProvider.getManyReference(resource, params),
    create: async (resource, params) => dataProvider.create(resource, params),
    update: async (resource, params) => dataProvider.update(resource, params),
    updateMany: async (resource, params) => dataProvider.updateMany(resource, params),
    delete: async (resource, params) => dataProvider.delete(resource, params),
    deleteMany: async (resource, params) => dataProvider.deleteMany(resource, params),
  };
}

// eslint-disable-next-line import/prefer-default-export
export { buildDataProvider };
