import { API, Auth } from 'aws-amplify';
import { buildDataProvider } from './dataProvider';

import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';

const amplifyDataProvider = buildDataProvider({ queries, mutations }, { enableAdminQueries: true });

async function ApiPost(path, body) {
  const init = {
    body,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };

  return API.post('AdminQueries', path, init);
}

async function customUpdate(resource, params) {
  const {
    id, data, previousData, ...meta
  } = params;
  if (resource === 'organizations') {
    const { usersIds, name, ...fields } = data;
    const { usersIds: previousUsersIds, ...previousFields } = previousData;
    const newParams = {
      id,
      data: { name, ...fields },
      previousData: { ...previousFields },
      ...meta,
    };
    await amplifyDataProvider.update(resource, newParams);
    const membersAdd = usersIds.filter((u) => !previousUsersIds.includes(u));
    const membersDelete = previousUsersIds.filter((u) => !usersIds.includes(u));
    const promises = [
      membersAdd.map((u) => ApiPost('/addUserToGroup', { username: u, orgId: id, orgName: name })),
      membersDelete.map((u) => ApiPost('/removeUserFromGroup', { username: u, orgId: id, orgName: name })),
    ].flat();
    return Promise.all(promises).then(() => ({ data }));
  }
  return amplifyDataProvider.update(resource, params);
}

async function customGetOne(resource, params) {
  const result = await amplifyDataProvider.getOne(resource, params);
  if (resource === 'organizations') {
    const listParams = {
      pagination: { page: 1, perPage: 25 },
      filter: {
        listUsersInGroup: {
          groupname: params.id,
        },
      },
    };
    const users = await amplifyDataProvider.getList('cognitoUsers', listParams);
    const usersIds = users.data.map((u) => u.id);
    result.data.usersIds = usersIds;
  }
  return result;
}

const CustomDataProvider = {
  getList: amplifyDataProvider.getList,
  getOne: customGetOne,
  getMany: amplifyDataProvider.getMany,
  getManyReference: amplifyDataProvider.getManyReference,
  create: amplifyDataProvider.create,
  update: customUpdate,
  updateMany: amplifyDataProvider.updateMany,
  delete: amplifyDataProvider.delete,
  deleteMany: amplifyDataProvider.deleteMany,
};

export default CustomDataProvider;
