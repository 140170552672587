/* eslint-disable no-underscore-dangle */
import { API } from 'aws-amplify';
import { HttpError } from 'react-admin';
import AdminQueries from './AdminQueries';
import Filter from './Filter';
import Pagination from './Pagination';

const defaultOptions = {
  authMode: 'AMAZON_COGNITO_USER_POOLS',
  enableAdminQueries: false,
};

class DataProvider {
  constructor(operations, options = {}) {
    this.queries = operations.queries;
    this.mutations = operations.mutations;

    this.authMode = options.authMode || defaultOptions.authMode;
    this.enableAdminQueries = options.enableAdminQueries || defaultOptions.enableAdminQueries;

    DataProvider.storageBucket = options.storageBucket;
    DataProvider.storageRegion = options.storageRegion;
  }

  async getList(resource, params) {
    if (this.enableAdminQueries && resource === 'cognitoUsers') {
      return AdminQueries.listCognitoUsers(params);
    }

    if (this.enableAdminQueries && resource === 'cognitoGroups') {
      return AdminQueries.listCognitoGroups(params);
    }

    const { filter } = params;

    let queryName = Filter.getQueryName(this.queries, filter);
    let queryVariables = Filter.getQueryVariables(filter);

    if (!queryName || !queryVariables) {
      // Default list query without filter
      queryName = DataProvider.getQueryName('list', resource);
    }

    const query = this.getQuery(queryName);

    if (!queryVariables) {
      queryVariables = {};
    }

    const { page, perPage } = params.pagination;

    // Defines a unique identifier of the query
    const querySignature = JSON.stringify({
      queryName,
      queryVariables,
      perPage,
    });

    const nextToken = Pagination.getNextToken(querySignature, page);

    // Checks if page requested is out of range
    if (typeof nextToken === 'undefined') {
      return {
        data: [],
        total: 0,
      }; // React admin will redirect to page 1
    }

    // Adds sorting if requested
    if (params.sort.field === queryName) {
      queryVariables.sortDirection = params.sort.order;
    }

    // Executes the query
    const queryData = (
      await this.graphql(query, {
        ...queryVariables,
        limit: perPage,
        nextToken,
      })
    )[queryName];

    // Saves next token
    Pagination.saveNextToken(queryData.nextToken, querySignature, page);

    // Computes pagination info
    const hasNextPage = !!queryData.nextToken;
    let pageInfo = {};
    if (!hasNextPage) {
      pageInfo = {
        total: (page - 1) * perPage + queryData.items.length,
      };
    } else {
      pageInfo = {
        pageInfo: {
          hasPreviousPage: (page > 1),
          hasNextPage: true,
        },
      };
    }

    return {
      data: queryData.items,
      ...pageInfo,
    };
  }

  async getOne(resource, params) {
    if (this.enableAdminQueries && resource === 'cognitoUsers') {
      return AdminQueries.getCognitoUser(params);
    }

    const queryName = DataProvider.getQueryName('get', resource);
    const query = this.getQuery(queryName);

    // Executes the query
    const queryData = (await this.graphql(query, { id: params.id }))[queryName];

    if (!queryData) {
      throw new HttpError('Not found', 404);
    }

    return {
      data: queryData,
    };
  }

  async getMany(resource, params) {
    if (this.enableAdminQueries && resource === 'cognitoUsers') {
      return AdminQueries.getManyCognitoUsers(params);
    }

    const queryName = DataProvider.getQueryName('get', resource);
    const query = this.getQuery(queryName);

    const dataPromises = params.ids.map((id) => this.graphql(query, { id }));
    const dataResults = await Promise.allSettled(dataPromises);

    const results = dataResults
      .filter((result) => result.status === 'fulfilled')
      .map((result) => result.value[queryName]);

    return {
      data: results,
    };
  }

  async getManyReference(resource, params) {
    const {
      filter = {}, id, pagination, sort, target,
    } = params;
    const splitTarget = target.split('.');

    // splitTarget is used to build the filter
    // It must be like: queryName.resourceID
    if (splitTarget.length === 2) {
      if (!filter[splitTarget[0]]) {
        filter[splitTarget[0]] = {};
      }

      filter[splitTarget[0]][splitTarget[1]] = id;
    } else {
      const queryName = DataProvider.getQueryNameMany('list', resource, target);
      if (!filter[queryName]) {
        filter[queryName] = {};
      }
      filter[queryName][target] = id;
    }

    return this.getList(resource, { pagination, sort, filter });
  }

  async create(resource, params) {
    if (this.enableAdminQueries && resource === 'cognitoUsers') {
      return AdminQueries.createCognitoUsers(params);
    }
    if (resource === 'organizations') {
      const { name } = params.data;
      const checkQueryName = 'organizationbyActiveAndName';
      const checkQuery = this.getQuery(checkQueryName);
      const checkData = (await this.graphql(checkQuery, { active: 1, name: { eq: name } }))[
        checkQueryName
      ];
      if (checkData.items && checkData.items.length > 0) {
        throw new HttpError(
          'errors.organizations.nameUniqueness',
          400,
          {
            errors: {
              name: { message: 'errors.organizations.nameUniqueness' },
            },
          },
        );
      }
    }

    const queryName = DataProvider.getQueryName('create', resource);
    const query = this.getQuery(queryName);

    // Executes the query
    const queryData = (await this.graphql(query, { input: params.data }))[
      queryName
    ];

    return {
      data: queryData,
    };
  }

  async update(resource, params) {
    const queryName = DataProvider.getQueryName('update', resource);
    const query = this.getQuery(queryName);

    // Removes non editable fields
    const { data, previousData } = params;
    delete data._deleted;
    delete data._lastChangedAt;
    delete data.createdAt;
    delete data.updatedAt;
    delete data.__typename;

    if (resource === 'organizations' && data.name && data.name !== previousData.name) {
      const checkQueryName = 'organizationbyActiveAndName';
      const checkQuery = this.getQuery(checkQueryName);
      const checkData = (await this.graphql(checkQuery, { active: 1, name: { eq: data.name } }))[
        checkQueryName
      ];
      if (checkData.items && checkData.items.length > 0) {
        throw new HttpError(
          'errors.organizations.nameUniqueness',
          400,
          {
            errors: {
              name: { message: 'errors.organizations.nameUniqueness' },
            },
          },
        );
      }
    }

    // Executes the query
    const queryData = (await this.graphql(query, { input: data }))[queryName];

    return {
      data: queryData,
    };
  }

  // This may not work for API that uses DataStore because
  // DataStore works with a _version field that needs to be properly set
  async updateMany(resource, params) {
    const queryName = DataProvider.getQueryName('update', resource);
    const query = this.getQuery(queryName);

    // Removes non editable fields
    const { data } = params;
    delete data._deleted;
    delete data._lastChangedAt;
    delete data.createdAt;
    delete data.updatedAt;
    delete data.__typename;

    const dataPromises = params.ids.map((id) => this.graphql(query, { input: { ...data, id } })
      .then(() => id));
    const dataResults = await Promise.allSettled(dataPromises);

    const ids = dataResults
      .filter((result) => result.status === 'fulfilled')
      .map((result) => result.value);

    return {
      data: ids,
    };
  }

  async delete(resource, params) {
    if (this.enableAdminQueries && resource === 'cognitoUsers') {
      return AdminQueries.deleteCognitoUsers(params);
    }

    const queryName = DataProvider.getQueryName('delete', resource);
    const query = this.getQuery(queryName);

    const { id, previousData } = params;
    const data = { id };

    if (previousData._version) {
      data._version = previousData._version;
    }

    // Executes the query
    const queryData = (await this.graphql(query, { input: data }))[queryName];

    return {
      data: queryData,
    };
  }

  async deleteMany(resource, params) {
    if (this.enableAdminQueries && resource === 'cognitoUsers') {
      return AdminQueries.deleteManyCognitoUsers(params);
    }

    const queryName = DataProvider.getQueryName('delete', resource);
    const query = this.getQuery(queryName);

    const dataPromises = params.ids.map((id) => this.graphql(query, { input: { id } })
      .then(() => id));
    const dataResults = await Promise.allSettled(dataPromises);

    const ids = dataResults
      .filter((result) => result.status === 'fulfilled')
      .map((result) => result.value);

    return {
      data: ids,
    };
  }

  getQuery(queryName) {
    if (this.queries[queryName]) {
      return this.queries[queryName];
    }

    if (this.mutations[queryName]) {
      return this.mutations[queryName];
    }

    // console.log(`Could not find query ${queryName}`);

    throw new Error('Data provider error');
  }

  static getQueryName(operation, resource) {
    const pluralOperations = ['list'];
    if (pluralOperations.includes(operation)) {
      return `${operation}${resource.charAt(0).toUpperCase() + resource.slice(1)}`;
    }
    // else singular operations ["create", "delete", "get", "update"]
    return `${operation}${resource.charAt(0).toUpperCase() + resource.slice(1, -1)}`;
  }

  static getQueryNameMany(operation, resource, target) {
    const queryName = DataProvider.getQueryName(operation, resource);

    return `${queryName}${target.charAt(0).toUpperCase() + target.slice(1, -2)}Id`;
  }

  async graphql(query, variables) {
    const queryResult = await API.graphql({
      query,
      variables,
      authMode: this.authMode,
    });

    if (queryResult.errors || !queryResult.data) {
      throw new Error('Data provider error');
    }

    return queryResult.data;
  }
}

export default DataProvider;
