import { Amplify } from 'aws-amplify';

import { Business, Person } from '@mui/icons-material';

import { Admin, Resource } from 'react-admin';
import { buildAuthProvider } from 'react-admin-amplify';

import CognitoHostedUIRedirect from './auth/CognitoHostedUIRedirect';
import I18nProvider from './providers/I18nProvider';
import CustomDataProvider from './providers/CustomDataProvider';

import {
  OrganizationList, OrganizationEdit, OrganizationCreate, OrganizationShow,
} from './resources/Organizations';
import {
  CognitoUserList, CognitoUserCreate, CognitoUserShow,
} from './resources/CognitoUsers';

import awsconfig from './aws-exports';

// Hack to fix https://github.com/aws-amplify/amplify-cli/issues/2792
const { host } = window.location;
if (awsconfig.oauth.redirectSignIn.includes(',')) {
  const filterHost = (url) => new URL(url).host === host;
  awsconfig.oauth.redirectSignIn = awsconfig.oauth.redirectSignIn
    .split(',')
    .filter(filterHost)
    .shift();
  awsconfig.oauth.redirectSignOut = awsconfig.oauth.redirectSignOut
    .split(',')
    .filter(filterHost)
    .shift();
}

Amplify.configure(awsconfig);

const App = () => (
  <Admin
    authProvider={buildAuthProvider()}
    dataProvider={CustomDataProvider}
    i18nProvider={I18nProvider}
    loginPage={CognitoHostedUIRedirect}
    requireAuth
    disableTelemetry
  >
    {/* TODO: Cette ressource ne devrait être visible que par les admins
      au vu des droits API */}
    <Resource
      name="organizations"
      icon={Business}
      list={OrganizationList}
      edit={OrganizationEdit}
      create={OrganizationCreate}
      show={OrganizationShow}
      recordRepresentation="name"
    />
    <Resource
      name="cognitoUsers"
      icon={Person}
      list={CognitoUserList}
      create={CognitoUserCreate}
      show={CognitoUserShow}
    />
  </Admin>
);

export default App;
