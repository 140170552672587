import polyglotI18nProvider from 'ra-i18n-polyglot';
import frenchMessages from 'ra-language-french';

const fr = {
  ...frenchMessages,
  resources: {
    organizations: {
      name: 'Organisation |||| Organisations',
      fields: {
        display_name: 'Nom complet',
        name: 'Nom abrégé',
        createdAt: 'Créée le',
        updatedAt: 'Modifée le',
      },
    },
    cognitoUsers: {
      name: 'Utilisateur |||| Utilisateurs',
      fields: {
        id: 'Identifiant',
        username: 'Identifiant',
        email: 'E-mail',
        given_name: 'Prénom',
        family_name: 'Nom de famille',
        Enabled: 'Actif',
        UserStatus: 'Cognito statut',
        email_verified: 'E-mail vérifié',
        UserCreateDate: 'Créé le',
        UserLastModifiedDate: 'Modifé le',
        isAdmin: 'Administrateur alfa-safety',
      },
    },
  },
  errors: {
    organizations: {
      nameUniqueness: 'Une organisation avec le même nom abrégé existe déjà',
    },
  },
};

const I18nProvider = polyglotI18nProvider(() => fr, 'fr');

export default I18nProvider;
