import {
  required,
  maxLength,
  regex,
  List,
  Edit,
  Create,
  Show,
  Datagrid,
  SimpleForm,
  SimpleShowLayout,
  BooleanField,
  TextField,
  DateField,
  TextInput,
  EditButton,
  ReferenceArrayField,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  TopToolbar,
  CreateButton,
  ExportButton,
} from 'react-admin';

const OrgListActions = () => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

const OrgFilters = [
  <TextInput
    source="organizationbyActiveAndName.name.beginsWith"
    label="Nom abrégé (commence par)"
    parse={(value) => (value == null ? '' : value.toLowerCase().trim())}
    alwaysOn
    resettable
  />,
];

export const OrganizationList = (props) => (
  <List perPage={25} actions={<OrgListActions />} filters={OrgFilters} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" sortable={false} />
      <TextField source="display_name" sortable={false} />
      <TextField source="name" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);

const validate = {
  display_name: required(),
  name: [required(), maxLength(32), regex(/^[a-z0-9][a-z0-9-]{0,30}[a-z0-9]$/)],
};

export const OrganizationEdit = (props) => (
  <Edit mutationMode="pessimistic" {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="display_name" validate={validate.display_name} />
      <TextInput source="name" validate={validate.name} />
      <ReferenceArrayInput reference="cognitoUsers" source="usersIds">
        <AutocompleteArrayInput optionText="id" optionValue="id" label="Membres" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export const OrganizationCreate = (props) => (
  <Create redirect="list" {...props}>
    <SimpleForm>
      <TextInput source="display_name" validate={validate.display_name} />
      <TextInput source="name" validate={validate.name} />
    </SimpleForm>
  </Create>
);

export const OrganizationShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="display_name" />
      <TextField source="name" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ReferenceArrayField reference="cognitoUsers" source="usersIds" label="Membres">
        <Datagrid rowClick="show" bulkActionButtons={false}>
          <TextField source="id" sortable={false} />
          <TextField source="email" sortable={false} />
          <TextField source="given_name" sortable={false} />
          <TextField source="family_name" sortable={false} />
          <BooleanField source="Enabled" sortable={false} />
        </Datagrid>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
);
