import { API, Auth } from 'aws-amplify';
import Pagination from './Pagination';

class AdminQueries {
  static async listCognitoUsers(params) {
    // Only 2 filters are available with Cognito users
    const { getUser, listUsersInGroup, q } = params.filter;

    // First filter getUser
    // Returns one Cognito user by username
    if (getUser) {
      try {
        const userData = await AdminQueries.get('/getUser', {
          username: getUser.username,
        });

        const user = AdminQueries.parseUser(userData);

        return {
          data: [user],
          total: 1,
        };
      } catch (e) {
        // Returns 400 when user not found
        if (e.response && e.response.status !== 400) {
          throw e;
        }

        return {
          data: [],
          total: 0,
        };
      }
    }

    let queryName = 'listUsers';
    let queryVariables = {};

    // Second filter listUsersInGroup
    // Returns Cognito users by group
    if (listUsersInGroup) {
      queryName = 'listUsersInGroup';
      queryVariables = {
        groupname: listUsersInGroup.groupname,
      };
    } else if (q) {
      queryVariables = {
        filter: q,
      };
    }

    const { page, perPage } = params.pagination;

    // Defines a unique identifier of the query
    const querySignature = JSON.stringify({
      queryName,
      queryVariables,
      perPage,
    });

    const token = Pagination.getNextToken(querySignature, page);

    // Checks if page requested is out of range
    if (typeof token === 'undefined') {
      return {
        data: [],
        total: 0,
      }; // React admin will redirect to page 1
    }

    let queryResult = { Users: [], NextToken: undefined };

    // Executes the query
    try {
      queryResult = await AdminQueries.get(`/${queryName}`, {
        ...queryVariables,
        limit: perPage,
        token,
      });
    } catch (e) {
      // Returns 400 when group not found
      if (!listUsersInGroup || (e.response && e.response.status !== 400)) {
        throw e;
      }
    }

    const users = queryResult.Users.map(AdminQueries.parseUser);
    const nextToken = queryResult.NextToken || null;

    // Saves next token
    Pagination.saveNextToken(nextToken, querySignature, page);

    // Computes pagination info
    const hasNextPage = !!nextToken;
    let pageInfo = {};
    if (!hasNextPage) {
      pageInfo = {
        total: (page - 1) * perPage + users.length,
      };
    } else {
      pageInfo = {
        pageInfo: {
          hasPreviousPage: (page > 1),
          hasNextPage: true,
        },
      };
    }

    return {
      data: users,
      ...pageInfo,
    };
  }

  static async listCognitoGroups(params) {
    // Only 1 filter is available with Cognito groups
    const { listGroupsForUser } = params.filter;

    let queryName = 'listGroups';
    let queryVariables = {};

    // Filter listGroupsForUser
    // Returns Cognito groups by user
    if (listGroupsForUser) {
      queryName = 'listGroupsForUser';
      queryVariables = {
        username: listGroupsForUser.username,
      };
    }

    const { page, perPage } = params.pagination;

    // Defines a unique identifier of the query
    const querySignature = JSON.stringify({
      queryName,
      queryVariables,
      perPage,
    });

    const token = Pagination.getNextToken(querySignature, page);

    // Checks if page requested is out of range
    if (typeof token === 'undefined') {
      return {
        data: [],
        total: 0,
      }; // React admin will redirect to page 1
    }

    let queryResult = { Groups: [], NextToken: undefined };

    // Executes the query
    try {
      queryResult = await AdminQueries.get(`/${queryName}`, {
        ...queryVariables,
        limit: perPage,
        token,
      });
    } catch (e) {
      // Returns 400 when user not found
      if (!listGroupsForUser || (e.response && e.response.status !== 400)) {
        throw e;
      }
    }

    const groups = queryResult.Groups.map(AdminQueries.parseGroup);
    const nextToken = queryResult.NextToken || null;

    // Saves next token
    Pagination.saveNextToken(nextToken, querySignature, page);

    // Computes pagination info
    const hasNextPage = !!nextToken;
    let pageInfo = {};
    if (!hasNextPage) {
      pageInfo = {
        total: (page - 1) * perPage + groups.length,
      };
    } else {
      pageInfo = {
        pageInfo: {
          hasPreviousPage: (page > 1),
          hasNextPage: true,
        },
      };
    }

    return {
      data: groups,
      ...pageInfo,
    };
  }

  static async getCognitoUser(params) {
    // Executes the query
    const userData = await AdminQueries.get('/getUser', {
      username: params.id,
    });

    const user = AdminQueries.parseUser(userData);

    return {
      data: user,
    };
  }

  static async getManyCognitoUsers(params) {
    const userDataPromises = params.ids.map((id) => AdminQueries.get('/getUser', { username: id }));
    const userDataResults = await Promise.allSettled(userDataPromises);

    const parsedUsers = userDataResults
      .filter((result) => result.status === 'fulfilled')
      .map((result) => AdminQueries.parseUser(result.value));

    return {
      data: parsedUsers,
    };
  }

  static async createCognitoUsers(params) {
    const { Username, ...fields } = await AdminQueries.post('/createUser', params.data);
    return {
      data: { id: Username, ...fields },
    };
  }

  static async deleteCognitoUsers(params) {
    return AdminQueries.post('/deleteUser', { username: params.id })
      .then(() => ({ data: params.previousData }));
  }

  static async deleteManyCognitoUsers(params) {
    return Promise.all(params.ids.map((id) => AdminQueries.post('/deleteUser', { username: id })))
      .then(() => ({ data: params.ids }));
  }

  static parseUser(user) {
    const {
      Username, Attributes, UserAttributes, ...fields
    } = user;

    const attributes = AdminQueries.parseAttributes(
      Attributes || UserAttributes,
    );

    return { ...fields, ...attributes, id: Username };
  }

  static parseGroup(group) {
    const { GroupName, ...fields } = group;

    return { ...fields, id: GroupName };
  }

  static parseAttributes(attributes) {
    return attributes.reduce((accumulator, attribute) => {
      const { Name, Value } = attribute;

      return {
        ...accumulator,
        [Name]: Value,
      };
    }, {});
  }

  /* istanbul ignore next */
  static async get(path, params) {
    const init = {
      queryStringParameters: params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return API.get('AdminQueries', path, init);
  }

  /* istanbul ignore next */
  static async post(path, body) {
    const init = {
      body,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return API.post('AdminQueries', path, init);
  }
}

export default AdminQueries;
