import {
  required,
  email,
  maxLength,
  regex,
  BooleanField,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  List,
  ReferenceArrayField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';

export const CognitoUserList = (props) => (
  <List
    bulkActionButtons={false}
    perPage={25}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="id" sortable={false} />
      <TextField source="email" sortable={false} />
      <TextField source="given_name" sortable={false} />
      <TextField source="family_name" sortable={false} />
      <BooleanField source="Enabled" sortable={false} />
      <DateField source="UserLastModifiedDate" sortable={false} />
      <DateField source="UserCreateDate" sortable={false} />
    </Datagrid>
  </List>
);

const validate = {
  username: [required(), maxLength(128), regex(/^[a-z0-9][a-z0-9.\-_]{0,126}[a-z0-9]$/)],
  email: [required(), email(), maxLength(2048)],
};

export const CognitoUserCreate = (props) => (
  <Create redirect="list" {...props}>
    <SimpleForm>
      <TextInput source="username" validate={validate.username} />
      <TextInput source="email" type="email" validate={validate.email} />
      <BooleanInput source="isAdmin" defaultValue={false} />
    </SimpleForm>
  </Create>
);

export const CognitoUserShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <BooleanField source="isAdmin" />
      <TextField source="email" />
      <TextField source="given_name" />
      <TextField source="family_name" />
      <BooleanField source="Enabled" />
      <TextField source="UserStatus" />
      <DateField source="UserLastModifiedDate" />
      <DateField source="UserCreateDate" />
      <ReferenceArrayField
        reference="organizations"
        source="organizationsIds"
        label="Organisations"
      >
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
);
